<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import InputGroup from "@/components/forms/input-group";
import SaveButton from "@/components/buttons/save-button";
import MessageModal from "@/components/modals/message-modal";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

export default {
    components: {
        Layout,
        PageHeader,
        InputGroup,
        SaveButton,
        MessageModal
    },
    data() {
        const id = this.$route.params.id;
        return {
            id: id, 
            title: `Ctc`,
            items: [
                {
                    text: "ctc"
                },
                {
                    text: 'Shipping',
                    active: true,
                },
            ],
            errors: {
            },
            formData: {
                courier_company: '',
                shipping_tracking_no: ''
            },
            order_id: this.$route.query.tenancy,
        }
    },
    created() {
    },
    methods: {
        async updateShipping() {
            const result = await axios.post(`${process.env.VUE_APP_APIURL}/ctc/add-shipping/${this.id}`, this.formData, {
                headers: authHeader(),
            }).catch( function (error) {
                alert("add error:" + error.message);
            });
            console.log(result);
            if (result.data.code === 'success') {
                this.$refs.messageModal.showModal('Your record has been updated successfully');
            } else if (result.data.code === 'invalid_field') {
                this.errors = result.data.errors;
            } else if (result.data.code === 'permission_denied') {
                this.$refs.messageModal.showModal('Permmission Denied');
            }
        },
        redirectPage() {
            this.$router.push({'name': "Ctc View", params: { id: this.order_id }})
        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <MessageModal ref="messageModal" @CloseBtn="redirectPage"/>
        <div class="card">
            <div class="card-body pt-0 mt-2">
                <div class="row mt-2">
                    <div class="col-12 col-md-4 mb-2">
                        <InputGroup type="text" id="courier_company" displayName="Courier Company" v-model="formData.courier_company" :error="errors.courier_company"/>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <InputGroup type="text" id="shipping_tracking_no" displayName="Shipping Tracking No" v-model="formData.shipping_tracking_no" :error="errors.shipping_tracking_no"/>
                    </div>
                    <div class="col-12">
                        <SaveButton @click="updateShipping" />
                        <a
                            class="btn btn-md btn-danger mb-2 ml-2"
                            @click="$router.go(-1)"
                        >
                            Cancel
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>